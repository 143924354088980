import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  IconButton,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
  Dialog,
  Paper,
  CircularProgress,
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckOverflow from "../../utils/CheckOverflow";
import TaskSheetReportAndResolve from "../TaskSheetReportAndResolve";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getReportIssueData,
  getReportPendingIssueData,
  updateERP,
} from "./mutation";
import dayjs from "dayjs";
import Loader from "../../components/Loader";
import { getUsers } from "../Blowroom/mutation/auth/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { handleToastMsg } from "../../components/Toast";
import { HALL_NO } from "../../config/masterdata";

function TaskSheet() {
  //for ERP checking whether the report should accept or decline
  const isERP = JSON.parse(localStorage.getItem("id")) === 84; //for production keep the id as 84

  const queryClient = useQueryClient();

  const { mutate, isLoading: isERPmutateLoading } = useMutation(updateERP);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterSlNo, setFilterSlNo] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [isForm, setIsform] = useState(false);
  const [isRaiseReport, setIsRaiseReport] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const {
    data: reportIssueData,
    isLoading: isReportDataLoading,
    refetch,
  } = useQuery(
    "task-sheets-report-issue",
    async () => {
      if (!isRaiseReport && !isERP) {
        setIsLoadingData(true);
      }

      try {
        //this is for conditionally call the api for getting issue data
        const response = isERP
          ? await getReportPendingIssueData()
          : await getReportIssueData();
        return response;
      } catch (error) {
        throw error;
      } finally {
        if (!isRaiseReport) {
          setIsLoadingData(false);
        }
      }
    },
    {
      refetchOnMount: true,
      // refetchInterval: 5000, // Poll every 5 seconds (5000ms)
    }
  );

  //this is for make fresh api call when component render and it will avoid the cache
  useEffect(() => {
    refetch();
  }, [refetch]);

  const { data: users, isLoading } = useQuery("users", getUsers, {
    refetchOnMount: true,
  });

  //this is for filter the manager users only
  const filteredManagers =
    users?.filter((item) => item.role === "MANAGER") || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    if (isNaN(event.target.value)) {
      alert("Please enter a number only");
      return;
    }

    setFilterSlNo(event.target.value);
    setPage(0);
  };

  const handleResetFilter = () => {
    setFilterSlNo("");
    setPage(0);
  };

  const handleStatusFilterChange = (event, newStatus) => {
    if (newStatus !== null) {
      setStatusFilter(newStatus);
      setPage(0);
    }
  };

  //this is for pretend the rerender issue when we give checkoverflow params
  const [filteredData, setFilteredData] = useState([]);
  const [erpSolution, setErpSolution] = useState("withSolution");
  const [hallNo, setHallNo] = useState("");

  const handleOptionChange = (e) => {
    setErpSolution(e.target.value);
  };

  //this is for showing the filtered data based on conditions
  useEffect(() => {
    if (!isERP) {
      const newFilteredData =
        reportIssueData?.data?.filter((row) => {
          const matchesFilterTerm =
            !filterSlNo || row.slNo.toString() === filterSlNo;
          const matchesStatusFilter =
            statusFilter === "All" || row.status === statusFilter;

          return matchesFilterTerm && matchesStatusFilter;
        }) || [];

      setFilteredData(newFilteredData);
    }

    if (isERP) {
      const newFilteredData =
        reportIssueData?.data?.filter((row) => {
          const matchesFilterTerm =
            !filterSlNo || row.slNo.toString() === filterSlNo;

          const matchesHallNumber = !hallNo || row.hall === hallNo;

          const matchesSolutionFilter =
            (erpSolution === "withSolution" &&
              row?.reportingManagerSolution &&
              row?.reportingManagerSolution?.every(
                (manager) =>
                  manager.solution !== null && manager.solution !== ""
              )) ||
            (erpSolution === "withOutSolution" &&
              row.reportingManagerSolution &&
              row.reportingManagerSolution.some(
                (manager) =>
                  manager.solution === null || manager.solution === ""
              ));

          return (
            matchesHallNumber && matchesFilterTerm && matchesSolutionFilter
          );
        }) || [];

      setFilteredData(newFilteredData);
    }
  }, [
    filterSlNo,
    reportIssueData?.data,
    statusFilter,
    hallNo,
    erpSolution,
    isERP,
  ]);


  //handle overflow text
  const [isOverflowDraft, addDraftRef] = CheckOverflow(filteredData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const handlePopoverOpen = (event, id, content) => {
    if (isOverflowDraft[id]) {
      setAnchorEl(event.currentTarget);
      setPopoverContent(content);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
  };

  const open = Boolean(anchorEl);

  const handleReport = () => {
    setIsform(true);
    setIsRaiseReport(true);
  };

  const goBack = () => {
    setIsform(false);
    setIsRaiseReport(false);
  };

  /**handle view the employee/sub manager's issued data */
  const [openDialog, setOpenDialog] = useState(false);
  const [showIssuedData, setShowIssuedData] = useState({});

  const [openDialogERP, setOpenDialogERP] = useState(false);
  const handleViewDetails = (rowData) => {
    if (isERP) {
      setOpenDialogERP(true);
      setShowIssuedData(rowData);
    } else {
      setOpenDialog(true);
      setShowIssuedData(rowData);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpenDialogERP(false);
    setShowIssuedData({});
  };

  //handle the image show in the dialog box
  const [openNew, setOpenNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpenNew(true);
  };

  const handleCloseImageDialog = () => {
    setOpenNew(false);
  };

  const [loadingKeyERP, setLoadingKeyERP] = useState("");
  const [loadingIdERP, setLoadingIdERP] = useState("");
  const handleAcceptDecline = (key, id) => {
    setLoadingKeyERP(key);
    setLoadingIdERP(id);
    // Map over the reportingManagerSolution array
    const updatedSolutions = showIssuedData?.reportingManagerSolution?.map(
      (item) => {
        // If the id matches, update the object by adding the 'accepted' field
        if (item.id === id) {
          return {
            ...item,
            accepted: key === "accepted" ? true : false,
            rejectedCount:
              key === "decline" ? item?.rejectedCount + 1 : item?.rejectedCount,
          };
        }
        return item; // If id doesn't match, return the item as it is
      }
    );

    // Update the state and prepare the payload
    const updatedData = {
      ...showIssuedData,
      reportingManagerSolution: updatedSolutions,
    };

    const params = { payload: updatedData, id: showIssuedData?.id };

    mutate(params, {
      onSuccess: () => {
        handleToastMsg("Data updated successfully", "success");
        queryClient.invalidateQueries("task-sheets-report-issue");
        refetch();
        // handleClose();
        //this is for stop closing the dialog box and will get instant change
        setShowIssuedData(updatedData);
      },
      onError: () => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  if (isReportDataLoading || isLoading || isLoadingData) {
    return <Loader />;
  }

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: "36px",
        "@media (max-width: 600px)": {
          width: "100%",
          marginLeft: "0",
          marginTop: "20px",
        },
      }}
      alignContent={"flex-start"}
      rowGap={4}
    >
      {isForm ? (
        <Grid container item xs={12}>
          {" "}
          <TaskSheetReportAndResolve
            goBack={goBack}
            isRaiseReport={isRaiseReport}
            filteredManagers={filteredManagers}
          />
        </Grid>
      ) : (
        <Grid container item xs={12} rowGap={4}>
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item sm={6} xs={9}>
              <TextField
                fullWidth
                placeholder="Enter Sl No to filter"
                value={filterSlNo}
                onChange={handleFilterChange}
                InputProps={{
                  endAdornment: filterSlNo && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleResetFilter}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isERP && (
              <Grid
                container
                item
                md={12}
                sm={12}
                xs={9}
                mt={3}
                alignItems="center"
                spacing={2}
              >
                <Grid item md={4.25} sm={6} xs={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={erpSolution}
                      name="radio-buttons-group"
                      onChange={handleOptionChange}
                      row
                    >
                      <FormControlLabel
                        value="withSolution"
                        control={<Radio />}
                        label="With Solution"
                      />
                      <FormControlLabel
                        value="withOutSolution"
                        control={<Radio />}
                        label="Without Solution"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    value={hallNo}
                    select
                    onChange={(e) => setHallNo(parseInt(e.target.value))}
                    type="number"
                    sx={{ width: 200 }}
                    id="outlined-basic"
                    label="Hall Number"
                    variant="outlined"
                    // onInput={handleCardingLineNumInput}
                  >
                    {HALL_NO?.map((hall) => (
                      <MenuItem key={hall} value={hall}>
                        {hall}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}

            {!isERP && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  // fullWidth
                  sx={{
                    mt: { md: 0, sm: 0, xs: 2 },
                    fontSize: { md: "18px", xs: "14" },
                  }}
                  style={{ textTransform: "none" }}
                  onClick={handleReport}
                >
                  Report an issue
                </Button>
              </Grid>
            )}
          </Grid>

          {!isERP && (
            <Grid
              container
              item
              md={6}
              sm={6}
              xs={8}
              style={{ overflowX: "auto" }}
            >
              <ToggleButtonGroup
                value={statusFilter}
                exclusive
                onChange={handleStatusFilterChange}
                aria-label="status filter"
                s
                fullWidth
              >
                <ToggleButton value="All" aria-label="all">
                  All
                </ToggleButton>
                <ToggleButton value="PENDING" aria-label="pending">
                  Pending
                </ToggleButton>
                <ToggleButton value="RESOLVED" aria-label="resolved">
                  Resolved
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

          <Grid container item md={12} sm={12} xs={9}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell style={{ maxWidth: "80px", fontWeight: "600" }}>
                      SL NO
                    </TableCell>
                    <TableCell style={{ maxWidth: "80px", fontWeight: "600" }}>
                      DATE
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      DESCRIPTION
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      DEPARTMENT
                    </TableCell>
                    <TableCell style={{ maxWidth: "100px", fontWeight: "600" }}>
                      REPORTING MANAGER
                    </TableCell>
                    {!isERP && (
                      <TableCell
                        style={{ maxWidth: "100px", fontWeight: "600" }}
                      >
                        ACTION / STATUS
                      </TableCell>
                    )}

                    <TableCell style={{ maxWidth: "20px", fontWeight: "600" }}>
                      VIEW
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData?.length > 0 ? (
                    filteredData
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{ maxWidth: "80px" }}>
                            <p className="overflowContain"> {row.slNo}</p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "80px" }}>
                            <p className="overflowContain">
                              {" "}
                              {dayjs(row.date).format("DD-MM-YYYY")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "150px" }}>
                            <p
                              // className="overflowContain"
                              ref={addDraftRef(`description-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `description-${row.id}`,
                                  row.description
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                color: "#e03131",
                                fontWeight: "bold",
                                textAlign: "justify",
                                wordWrap: "break-word", // Breaks long words to fit within the container
                                whiteSpace: "normal", // Allow wrapping on multiple lines
                              }}
                            >
                              {row.description}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`department-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `department-${row.id}`,
                                  row?.department
                                    ?.map((item) => item)
                                    .join(", ")
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row?.department?.map((item) => item).join(", ")}
                            </p>
                          </TableCell>
                          <TableCell style={{ maxWidth: "100px" }}>
                            <p
                              className="overflowContain"
                              ref={addDraftRef(`reportingManager-${row.id}`)}
                              onMouseEnter={(e) =>
                                handlePopoverOpen(
                                  e,
                                  `reportingManager-${row.id}`,
                                  row?.reportingManager
                                    ?.map((item) => item?.name)
                                    .join(", ")
                                )
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              {row?.reportingManager
                                ?.map((item) => item?.name)
                                .join(", ")}
                            </p>
                          </TableCell>

                          {!isERP && (
                            <TableCell style={{ maxWidth: "100px" }}>
                              <p
                                className="overflowContain"
                                ref={addDraftRef(`status-${row.id}`)}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    `status-${row.id}`,
                                    row.status
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                                style={{
                                  color: `${
                                    row.status === "PENDING"
                                      ? "#f08c00"
                                      : "#2f9344"
                                  }`,
                                }}
                              >
                                {row.status}
                              </p>
                            </TableCell>
                          )}

                          <TableCell sx={{ maxWidth: "20px" }}>
                            <VisibilityIcon
                              sx={{
                                fontSize: "30px",
                                color: "#00897B",
                                cursor: "pointer",
                              }}
                              onClick={() => handleViewDetails(row)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Popover
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <p style={{ padding: "15px" }}>{popoverContent}</p>
              </Popover>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={filteredData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          {/**this dialog box for showing the image to the manager */}
          <Dialog open={openNew} onClose={handleCloseImageDialog} maxWidth="lg">
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseImageDialog}
                style={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="custom-scrollbar">
              <Grid>
                <img
                  src={selectedImage}
                  alt="issue"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
            </DialogContent>
          </Dialog>

          <Dialog
            open={openDialog}
            onClose={handleClose}
            maxWidth="lg"
            PaperProps={{
              sx: {
                width: "100%", // 80% of the viewport width
                height: "100%", // 80% of the viewport height
                padding: "20px",
              },
            }}
          >
            <Grid container item xs={12} alignContent={"flex-start"} rowGap={2}>
              <Grid container item xs={12} justifyContent={"flex-end"}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>

              <Grid container item xs={12} rowGap={3}>
                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Sl No :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.slNo}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Hall :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.hall}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Date :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {dayjs(showIssuedData?.date).format("DD-MM-YYYY")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Department :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.department
                        ?.map((dept) => dept)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Reporting Manager :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.reportingManager
                        ?.map((manager) => manager?.name)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Description :{" "}
                    <span style={{ fontWeight: "400", color: "#e03131" }}>
                      {showIssuedData?.description}{" "}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Status :{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        color:
                          showIssuedData?.status === "RESOLVED"
                            ? "#2f9344"
                            : "#f08c00",
                      }}
                    >
                      {showIssuedData?.status}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12}>
                  <p style={{ fontWeight: "700" }}>Solution : </p>
                </Grid>

                <Grid container item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                          <TableCell style={{ fontWeight: "700" }}>
                            NAME
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            SOLUTION
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            SOLUTION DATE
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            SEEKED DATE
                          </TableCell>
                          <TableCell style={{ fontWeight: "700" }}>
                            IMAGE
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showIssuedData?.reportingManagerSolution?.map(
                          (manager, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.name}
                              </TableCell>
                              <TableCell
                                style={{ fontWeight: "400", maxWidth: "150px" }}
                              >
                                {manager?.solution ? (
                                  <p
                                    style={{
                                      textAlign: "justify",
                                      wordWrap: "break-word", // Breaks long words to fit within the container
                                      whiteSpace: "normal", // Allow wrapping on multiple lines
                                    }}
                                  >
                                    {manager?.solution}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>

                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.solutionDate ? (
                                  <p>
                                    {dayjs(manager?.solutionDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>
                              <TableCell style={{ fontWeight: "400" }}>
                                {manager?.seekedDate ? (
                                  <p>
                                    {dayjs(manager?.seekedDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                ) : (
                                  <p>NA</p>
                                )}
                              </TableCell>
                              <TableCell>
                                {manager?.image ? (
                                  <img
                                    src={manager?.image}
                                    style={{
                                      width: "100px", // Adjusted to fit in the table cell
                                      height: "100px",
                                      objectFit: "cover",
                                    }}
                                    alt="issue"
                                  />
                                ) : (
                                  "NA"
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>

          <Dialog
            open={openDialogERP}
            onClose={handleClose}
            maxWidth="lg"
            PaperProps={{
              sx: {
                width: "100%", // 80% of the viewport width
                height: "100%", // 80% of the viewport height
                padding: "20px",
              },
            }}
          >
            <Grid container item xs={12} alignContent={"flex-start"} rowGap={2}>
              <Grid container item xs={12} justifyContent={"flex-end"}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>

              <Grid container item xs={12} rowGap={3}>
                <Grid container item xs={12} md={4}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Sl No :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.slNo}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12} md={4}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Hall :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.hall}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12} md={4}>
                  <p style={{ fontWeight: "700" }}>
                    {" "}
                    Date :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {dayjs(showIssuedData?.date).format("DD-MM-YYYY")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12} md={4}>
                  <p
                    style={{
                      fontWeight: "700",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "wrap",
                    }}
                  >
                    {" "}
                    Department :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.department
                        ?.map((dept) => dept)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12} md={8}>
                  <p
                    style={{
                      fontWeight: "700",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "wrap",
                    }}
                  >
                    {" "}
                    Reporting Manager :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {showIssuedData?.reportingManager
                        ?.map((manager) => manager?.name)
                        .join(", ")}
                    </span>{" "}
                  </p>
                </Grid>

                <Grid container item xs={12} style={{ rowGap: "10px" }}>
                  <Grid item xs={12}>
                    <b>Description of issue :</b>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows={4}
                      disabled
                      value={showIssuedData?.description}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {showIssuedData?.image?.length > 0 && (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ textAlign: "center", rowGap: "10px" }}
                  >
                    <Grid item xs={12}>
                      <b>Issue image</b>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      rowGap={5}
                      // spacing={2}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        // justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      {" "}
                      {showIssuedData?.image?.map((imgUrl, index) => {
                        return (
                          <Grid
                            item
                            xs={5.5}
                            md={3.8}
                            key={index}
                            style={{
                              // width: "200px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={imgUrl}
                              alt="issue"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() => handleClickOpen(imgUrl)}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}

                {/* {showIssuedData?.video !== null && (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ textAlign: "center", rowGap: "10px" }}
                  >
                    <Grid item xs={12}>
                      <b>Issue video</b>
                    </Grid>

                    <video
                      src={showIssuedData?.video}
                      alt="Issued"
                      controls
                      style={{
                        height: "200px",
                        width: "200px",
                      }}
                    />
                  </Grid>
                )} */}

                <Grid container item xs={12}>
                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                    }}
                  />
                </Grid>

                <Grid container item xs={12}>
                  {showIssuedData?.reportingManagerSolution?.map(
                    (managerSolution, index) => {
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          key={index}
                          justifyContent={"space-between"}
                          style={{
                            rowGap: "20px",
                            padding: "20px 0",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <Grid
                            container
                            item
                            xs={7}
                            md={8}
                            style={{ rowGap: "10px" }}
                          >
                            <Grid item xs={12}>
                              <b>{`Solution ${index + 1}: ${
                                managerSolution?.name
                              }`}</b>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                multiline
                                rows={4}
                                disabled
                                value={managerSolution?.solution}
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={4}
                            md={3}
                            style={{ textAlign: "center", rowGap: "10px" }}
                          >
                            <Grid item xs={12}>
                              <b>Image</b>
                            </Grid>

                            <Grid item xs={12}>
                              {managerSolution?.image?.length > 0 ? (
                                <Grid item xs={12}>
                                  <img
                                    src={managerSolution?.image}
                                    alt="issue"
                                    style={{
                                      width: "200px",
                                      height: "150px",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleClickOpen(managerSolution?.image)
                                    }
                                  />
                                </Grid>
                              ) : (
                                <p>No image preview</p>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end"
                            spacing={2}
                          >
                            {managerSolution?.accepted === null &&
                            (managerSolution?.solution !== null ||
                              managerSolution?.image !== null) ? (
                              <>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      backgroundColor: "#2f9e44",
                                      color: "white",
                                      textTransform: "none",
                                    }}
                                    onClick={() =>
                                      handleAcceptDecline(
                                        "accepted",
                                        managerSolution?.id
                                      )
                                    }
                                    disabled={isERPmutateLoading}
                                  >
                                    {isERPmutateLoading &&
                                    loadingKeyERP === "accepted" &&
                                    loadingIdERP === managerSolution?.id ? (
                                      <CircularProgress
                                        size={24}
                                        sx={{ color: "#FFF" }}
                                      />
                                    ) : (
                                      "Accept"
                                    )}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                      backgroundColor: "#e03131",
                                      color: "white",
                                      textTransform: "none",
                                    }}
                                    onClick={() =>
                                      handleAcceptDecline(
                                        "decline",
                                        managerSolution?.id
                                      )
                                    }
                                    disabled={isERPmutateLoading}
                                  >
                                    {isERPmutateLoading &&
                                    loadingKeyERP === "decline" &&
                                    loadingIdERP === managerSolution?.id ? (
                                      <CircularProgress
                                        size={24}
                                        sx={{ color: "#FFF" }}
                                      />
                                    ) : (
                                      "Decline"
                                    )}
                                  </Button>
                                </Grid>
                              </>
                            ) : managerSolution.accepted === true ? (
                              <b style={{ color: "#2f9e44" }}>ACCEPTED</b>
                            ) : (
                              managerSolution.accepted === false && (
                                <b style={{ color: "#e03131" }}>DECLINED</b>
                              )
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
}

export default TaskSheet;
