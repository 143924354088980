import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Webcam from "react-webcam";
import { handleToastMsg } from "../../components/Toast";
import CloseIcon from "@mui/icons-material/Close";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import { DEPARTMENTS_TASKSHEETS, HALL_NO } from "../../config/masterdata";
import { useMutation, useQueryClient } from "react-query";
import {
  createTaskSheetsIssueData,
  updateTaskSheetsIssueData,
} from "./mutation";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { FileUpload, PlayCircleOutline, StopCircle } from "@mui/icons-material";

function TaskSheetReportAndResolve({
  isManager,
  isSeek,
  isResolve,
  goBack,
  isRaiseReport,
  showIssueData,
  filteredManagers,
  isTelevision,
}) {
  const queryClient = useQueryClient();

  const [selectedDate, setSelectedDate] = useState(null);
  const today = dayjs();

  //this is for manager resolve
  const [solution, setSolution] = useState("");
  const [seekReason, setSeekReason] = useState("");

  const handleGoBack = () => {
    goBack();
  };

  // this all for raise an report
  const [departments, setDepartments] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [hallNo, setHallNo] = useState("");
  const [issue, setIssue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilesMulti, setSelectedFilesMulti] = useState([]);
  const [openCamera, setOpenCamera] = useState(false);
  const [facingMode, setFacingMode] = useState("environment");

  // video content start  ---start----------------------------------------------------------------------------------------

  const [openDialogVideo, setOpenDialogVideo] = useState(false);
  const [openVideoCamera, setOpenVideoCamera] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  // const [videoBlob, setVideoBlob] = useState(null);
  const [videoRecordUrl, setVideoRecordUrl] = useState(null);
  const [videoRecordFile, setVideoRecordFile] = useState(null);
  // const [videoBlobUrl, setVideoBlobUrl] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0); // in seconds
  const [recordingInterval, setRecordingInterval] = useState(null);
  const [recordingTimeout, setRecordingTimeout] = useState(null);
  // const [urlFile, setUrlFile] = useState(null);
  const [, setUrlFile] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const handleCloseDialogVideo = () => {
    setOpenDialogVideo(false);
    setOpenVideoCamera(false);
    setIsRecording(false);
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    clearInterval(recordingInterval);
    setSelectedVideo(null);
    setUrlFile(null);
    setVideoRecordUrl(null);
    setVideoRecordFile(null);
    setLoadingVideo(false);
  };

  // video upload file
  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type !== "video/mp4") {
      alert("Please select a valid MP4 video file.");
      return;
    }
    if (file && file.size <= 30485760) {
      const videoElement = document.createElement("video");
      const fileUrl = URL.createObjectURL(file); // Blob URL for original video
      videoElement.src = fileUrl;
      videoElement.muted = true; // Mute the video during processing

      videoElement.onloadedmetadata = () => {
        // Check video duration
        if (videoElement.duration <= 31) {
          compressVideo(videoElement, fileUrl);
        } else {
          alert("Please select a video file shorter than 31 seconds.");
          URL.revokeObjectURL(fileUrl); // Clean up Blob URL to avoid memory leaks
        }
      };
      videoElement.onerror = () => {
        alert(
          "Error loading the video. Please ensure it is a valid video file and not corrupted."
        );
        URL.revokeObjectURL(fileUrl); // Clean up Blob URL
      };
    } else {
      alert("Please select a video file less than 30MB.");
    }
  };
  // const compressVideo = (videoElement, fileUrl) => {
  //   if (!selectedVideo) {
  //     setLoadingVideo(true);
  //   }
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   const outputWidth = 320; // e.g., reduce the resolution to 320px width
  //   const outputHeight =
  //     (videoElement.videoHeight / videoElement.videoWidth) * outputWidth;

  //   canvas.width = outputWidth;
  //   canvas.height = outputHeight;
  //   const stream = canvas.captureStream();
  //   const mediaRecorder = new MediaRecorder(stream, {
  //     mimeType: "video/webm; codecs=vp9", // WebM format is widely supported
  //   });

  //   const chunks = [];
  //   let isRecording = false; // Flag to ensure recording happens only once

  //   mediaRecorder.ondataavailable = (event) => {
  //     if (event.data.size > 0) {
  //       chunks.push(event.data);
  //     }
  //   };

  //   mediaRecorder.onstop = () => {
  //     if (!isRecording) return; // Prevent multiple executions
  //     isRecording = false; // Reset the flag after recording is done

  //     const compressedBlob = new Blob(chunks, { type: "video/webm" });
  //     const base64Reader = new FileReader();

  //     // Convert Blob to Base64
  //     base64Reader.onloadend = () => {
  //       const base64Url = base64Reader.result;
  //       if (typeof base64Url === "string") {
  //         // Store the Base64 URL for backend submission
  //         setUrlFile(base64Url);
  //         // Store Blob URL for video preview in the frontend (original video)
  //         setSelectedVideo(fileUrl); // Set original video Blob URL
  //         // Close dialog after valid selection
  //         setOpenDialogVideo(false);
  //         setLoadingVideo(false);
  //       } else {
  //         alert("Error converting the video to Base64 format.");
  //       }
  //     };

  //     base64Reader.readAsDataURL(compressedBlob); // Read the compressed video as Base64
  //     URL.revokeObjectURL(fileUrl); // Clean up Blob URL to avoid memory leaks
  //   };

  //   // Start recording and draw the video frames onto the canvas
  //   mediaRecorder.start();
  //   isRecording = true;

  //   const drawFrame = () => {
  //     if (!isRecording) return; // Exit if recording has stopped
  //     ctx.drawImage(videoElement, 0, 0, outputWidth, outputHeight);

  //     if (!videoElement.paused && !videoElement.ended) {
  //       requestAnimationFrame(drawFrame);
  //     } else {
  //       mediaRecorder.stop();
  //     }
  //   };

  //   // Start playing the video and begin drawing frames
  //   videoElement.play();
  //   drawFrame();
  // };

  //video base4 url and blob

  // const convertVideoToBase64AndBlob = (videoElement, fileUrl) => {
  //   if (!selectedVideo) {
  //     setLoadingVideo(true);
  //   }

  //   const base64Reader = new FileReader();

  //   // Convert the video file to Base64
  //   base64Reader.onloadend = () => {
  //     const base64Url = base64Reader.result;
  //     if (typeof base64Url === "string") {
  //       // Store the Base64 URL for backend submission
  //       setUrlFile(base64Url);
  //       // Store Blob URL for video preview in the frontend (original video)
  //       setSelectedVideo(fileUrl); // Set original video Blob URL
  //       // Close dialog after valid selection
  //       setOpenDialogVideo(false);
  //       setLoadingVideo(false);
  //     } else {
  //       alert("Error converting the video to Base64 format.");
  //     }
  //   };

  //   // Read the video file as Base64
  //   fetch(fileUrl)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       base64Reader.readAsDataURL(blob);
  //     })
  //     .catch(() => {
  //       alert("Error reading the video file.");
  //       setLoadingVideo(false);
  //     });
  // };

  const compressVideo = (videoElement, fileUrl) => {
    //compressvideo but is hight quality
    if (!selectedVideo) {
      setLoadingVideo(true);
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Increase the resolution for better quality
    const outputWidth = 640; // Adjust to 640px or higher
    const outputHeight =
      (videoElement.videoHeight / videoElement.videoWidth) * outputWidth;

    canvas.width = outputWidth;
    canvas.height = outputHeight;

    const stream = canvas.captureStream();
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp9",
      videoBitsPerSecond: 2500000, // Increase bitrate (2.5 Mbps for better quality)
    });

    const chunks = [];
    let isRecording = false; // Flag to ensure recording happens only once

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      if (!isRecording) return; // Prevent multiple executions
      isRecording = false; // Reset the flag after recording is done

      const compressedBlob = new Blob(chunks, { type: "video/webm" });
      const base64Reader = new FileReader();

      // Convert Blob to Base64
      base64Reader.onloadend = () => {
        const base64Url = base64Reader.result;
        if (typeof base64Url === "string") {
          // Store the Base64 URL for backend submission
          setUrlFile(base64Url);
          // Store Blob URL for video preview in the frontend (original video)
          setSelectedVideo(fileUrl); // Set original video Blob URL
          // Close dialog after valid selection
          setOpenDialogVideo(false);
          setLoadingVideo(false);
        } else {
          alert("Error converting the video to Base64 format.");
        }
      };

      base64Reader.readAsDataURL(compressedBlob); // Read the compressed video as Base64
      URL.revokeObjectURL(fileUrl); // Clean up Blob URL to avoid memory leaks
    };

    // Start recording and draw the video frames onto the canvas
    mediaRecorder.start();
    isRecording = true;

    const drawFrame = () => {
      if (!isRecording) return; // Exit if recording has stopped
      ctx.drawImage(videoElement, 0, 0, outputWidth, outputHeight);

      if (!videoElement.paused && !videoElement.ended) {
        requestAnimationFrame(drawFrame);
      } else {
        mediaRecorder.stop();
      }
    };

    // Start playing the video and begin drawing frames
    videoElement.play();
    drawFrame();
  };
  //open camera
  const handleCameraVideoClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      mediaStreamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setOpenVideoCamera(true);
    } catch (error) {
      console.error("Error accessing webcam: ", error);
    }
  };

  //video recording part
  const handleStartStopRecording = () => {
    if (isRecording) {
      // Stop recording
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(recordingInterval); // Clear the timer
      clearTimeout(recordingTimeout); // Clear the timeout for automatic stopping
    } else {
      // Start recording
      const mediaRecorder = new MediaRecorder(mediaStreamRef.current, {
        mimeType: "video/x-matroska", // Change to MKV format
      });
      mediaRecorderRef.current = mediaRecorder;

      const chunks = []; // Array to hold recorded data chunks

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data); // Push data chunks into the array
        }
      };

      mediaRecorder.onstop = () => {
        // Create a blob from the recorded chunks
        const blob = new Blob(chunks, { type: "video/x-matroska" });
        const fileUrl = URL.createObjectURL(blob); // Create a URL for the recorded video

        // Set videoBlob for preview
        // setVideoBlob(fileUrl);

        // Create a video element to play the recorded video
        const videoElement = document.createElement("video");
        videoElement.src = fileUrl;
        videoElement.onloadedmetadata = () => {
          convertVideoToUrls(videoElement, fileUrl); // Compress the video after it is loaded
        };

        // Convert Blob URL to Base64 for storage
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          // const base64Url = fileReader.result; // Base64 encoded string
          // setVideoBlobUrl(base64Url); // Store the Base64 URL for use
        };
        fileReader.readAsDataURL(blob); // Read Blob data as Base64
      };

      mediaRecorder.onerror = (e) => {
        console.error("MediaRecorder error: ", e);
      };

      mediaRecorder.start();
      setIsRecording(true);

      // Start recording timer
      const startTime = Date.now();
      const interval = setInterval(() => {
        setRecordingTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
      setRecordingInterval(interval);

      // Automatically stop recording after 30 seconds
      const timeout = setTimeout(() => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);

        clearInterval(recordingInterval);
      }, 30000); // 30 seconds limit
      setRecordingTimeout(timeout);
    }
  };

  //base64 url and blob url converter

  // const convertVideoToUrls = (videoElement, fileUrl) => {
  //   // Show loading indicator
  //   setLoadingVideo(true);

  //   const base64Reader = new FileReader();

  //   // Create a new canvas
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");

  //   // Set the canvas dimensions to match the video
  //   const outputWidth = videoElement.videoWidth;
  //   const outputHeight = videoElement.videoHeight;

  //   canvas.width = outputWidth;
  //   canvas.height = outputHeight;

  //   // Create an audio context
  //   const audioContext = new (window.AudioContext ||
  //     window.webkitAudioContext)();
  //   const source = audioContext.createMediaElementSource(videoElement);
  //   const gainNode = audioContext.createGain();

  //   // Set the gain to 0 to mute
  //   gainNode.gain.setValueAtTime(0, audioContext.currentTime);
  //   source.connect(gainNode);
  //   gainNode.connect(audioContext.destination); // Connect to speakers

  //   // Create a MediaStream that includes both video and audio tracks
  //   const stream = videoElement.captureStream(); // Captures video and audio from the video element
  //   const mediaRecorder = new MediaRecorder(stream, {
  //     mimeType: "video/webm; codecs=vp9,opus", // Include audio codec
  //   });

  //   const chunks = [];

  //   mediaRecorder.ondataavailable = (event) => {
  //     if (event.data.size > 0) {
  //       chunks.push(event.data);
  //     }
  //   };

  //   mediaRecorder.onstop = () => {
  //     // Create Blob from recorded chunks
  //     const blob = new Blob(chunks, { type: "video/webm" });

  //     // Create Blob URL for preview
  //     const blobUrl = URL.createObjectURL(blob);
  //     setVideoRecordFile(blobUrl); // Set original video Blob URL

  //     // Convert Blob to Base64
  //     base64Reader.onloadend = () => {
  //       const base64Url = base64Reader.result;
  //       if (typeof base64Url === "string") {
  //         // Store the Base64 URL for backend submission
  //         setVideoRecordUrl(base64Url);
  //       } else {
  //         alert("Error converting the video to Base64 format.");
  //       }
  //       setLoadingVideo(false); // Hide loading indicator
  //     };

  //     base64Reader.readAsDataURL(blob); // Read the Blob as Base64
  //     URL.revokeObjectURL(fileUrl); // Clean up Blob URL to avoid memory leaks
  //   };

  //   // Start recording
  //   mediaRecorder.start();

  //   const drawFrame = () => {
  //     // Draw the current video frame onto the canvas
  //     ctx.drawImage(videoElement, 0, 0, outputWidth, outputHeight);

  //     if (!videoElement.paused && !videoElement.ended) {
  //       requestAnimationFrame(drawFrame); // Continue drawing frames
  //     } else {
  //       mediaRecorder.stop(); // Stop recording when the video ends
  //     }
  //   };

  //   videoElement.play(); // Start playing the video
  //   drawFrame(); // Start drawing frames
  // };

  // Increase bitrate for better quality (1.5 Mbps)
  const convertVideoToUrls = (videoElement, fileUrl) => {
    if (!selectedVideo) {
      setLoadingVideo(true);
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Increase resolution for better quality
    const outputWidth = 640; // Higher resolution (e.g., 640px width)
    const outputHeight =
      (videoElement.videoHeight / videoElement.videoWidth) * outputWidth;

    canvas.width = outputWidth;
    canvas.height = outputHeight;

    const stream = canvas.captureStream(24); // Use 24 fps for smoother motion
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp9",
      videoBitsPerSecond: 2500000, // Increase bitrate for better quality (1.5 Mbps)
    });

    const chunks = [];
    let isRecording = false;

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      if (!isRecording) return;
      isRecording = false;

      const compressedBlob = new Blob(chunks, { type: "video/webm" });
      const base64Reader = new FileReader();

      // Convert Blob to Base64
      base64Reader.onloadend = () => {
        const base64Url = base64Reader.result;
        if (typeof base64Url === "string") {
          // Store the Base64 URL for backend submission
          setVideoRecordUrl(base64Url);
          setVideoRecordFile(fileUrl);
        } else {
          alert("Error converting the video to Base64 format.");
        }
        setLoadingVideo(false); // Hide loading indicator
      };

      base64Reader.readAsDataURL(compressedBlob);
      URL.revokeObjectURL(fileUrl);
    };

    mediaRecorder.start();
    isRecording = true;

    const drawFrame = () => {
      if (!isRecording) return;
      ctx.drawImage(videoElement, 0, 0, outputWidth, outputHeight);

      if (!videoElement.paused && !videoElement.ended) {
        setTimeout(() => requestAnimationFrame(drawFrame), 1000 / 24); // 24 fps limit for smoother motion
      } else {
        mediaRecorder.stop();
      }
    };

    videoElement.play();
    videoElement.muted = true;
    drawFrame();
  };

  //video timing function
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  //video capture
  const captureVideo = () => {
    handleCloseDialogVideo();
    setSelectedVideo(videoRecordFile);
    setOpenVideoCamera(false);
    setUrlFile(videoRecordUrl);
    setSelectedVideo(videoRecordFile);
  };

  useEffect(() => {
    return () => clearInterval(recordingInterval); // Clean up on unmount
  }, [recordingInterval]);

  //video end --end---------------------------------------------------------------------------------------------------

  const handleToggleCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "user" ? "environment" : "user"
    );
  };

  const handleManagerChange = (event) => {
    const { value } = event.target;

    const selectedIds = typeof value === "string" ? value.split(",") : value;

    const selectedManagers = selectedIds
      ?.map((id) => {
        const manager = filteredManagers?.find((mgr) => mgr.id === id);
        return manager ? { id: manager.id, name: manager.name } : null;
      })
      .filter(Boolean);

    setReportingManager(selectedManagers);
  };

  const toggleManagerSelection = (manager) => {
    setReportingManager((prev) => {
      const isSelected = prev.some((m) => m.id === manager.id);
      if (isSelected) {
        return prev.filter((m) => m.id !== manager.id);
      }
      return [...prev, { id: manager.id, name: manager.name }];
    });
  };

  const webcamRef = useRef(null);

  const webcamRefVideo = useRef(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenCamera(false);
  };

  // const handleOpenDialogVideo = () => {
  //   setOpenDialogVideo(true);
  // };

  //handle image select for single and multiple(MANAGER AND EMPLOYEE)
  const handleFileChange = (e) => {
    //this is for raise report(IMAGE - PUT) manager
    if (isResolve && !isTelevision) {
      const file = e.target.files[0];

      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        // Check if the file size is less than or equal to 1MB
        if (file.size <= 1048576) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setSelectedFile(reader.result);
          };
          setOpenDialog(false);
        } else {
          // If the file size is greater than 1MB, compress it
          const img = new Image();
          const reader = new FileReader();

          reader.onload = (event) => {
            img.src = event.target.result;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              const maxWidth = 1000; // Adjust this as needed
              const maxHeight = 1000; // Adjust this as needed
              let width = img.width;
              let height = img.height;

              // Calculate the new dimensions
              if (width > height && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              } else if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              // Start with a higher quality and decrease it gradually
              let quality = 1.0; // Further increased initial quality
              let compressedDataUrl = canvas.toDataURL(file.type, quality);

              while (compressedDataUrl.length > 1048576 && quality > 0.3) {
                quality -= 0.02; // Decrease the quality in smaller steps
                compressedDataUrl = canvas.toDataURL(file.type, quality);
              }

              // Ensure the final size is close to 1MB but not greater

              setSelectedFile(compressedDataUrl);
              setOpenDialog(false);
            };
          };

          reader.readAsDataURL(file);
        }
      } else {
        handleToastMsg("Please select a JPG or PNG file only.", "info");
        return;
      }
    }

    //this is for raise report(IMAGE - CREATE) employee or sub manager
    if (isRaiseReport) {
      const files = e.target.files;
      //checking for selected files is jpg or png format
      Array.from(files)?.forEach((file) => {
        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          handleToastMsg("Please select JPG or PNG files only.", "info");
          return;
        }
      });

      const newFiles = Array.from(files).filter((file) => {
        return (
          file && (file.type === "image/jpeg" || file.type === "image/png")
        );
      });

      newFiles.forEach((file) => {
        if (file.size <= 1048576) {
          // Directly add the file if it is less than or equal to 1MB
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setSelectedFilesMulti((prevFiles) => [...prevFiles, reader.result]);
          };
        } else {
          // Compress the file if it is larger than 1MB
          const img = new Image();
          const reader = new FileReader();

          reader.onload = (event) => {
            img.src = event.target.result;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              const maxWidth = 1000; // Adjust this as needed
              const maxHeight = 1000; // Adjust this as needed
              let width = img.width;
              let height = img.height;

              // Calculate the new dimensions
              if (width > height && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              } else if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              // Start with high quality and decrease it gradually
              let quality = 1.0;
              let compressedDataUrl = canvas.toDataURL(file.type, quality);

              while (compressedDataUrl.length > 1048576 && quality > 0.3) {
                quality -= 0.005; // Decrease the quality gradually
                compressedDataUrl = canvas.toDataURL(file.type, quality);
              }

              // Add the compressed image to the selected files
              setSelectedFilesMulti((prevFiles) => [
                ...prevFiles,
                compressedDataUrl,
              ]);
            };
          };
          reader.readAsDataURL(file);
        }
      });
      setOpenDialog(false);
    }
  };

  const handleCameraClick = () => {
    setOpenCamera(true);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        if (blob.size <= 1048576) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            if (isResolve && !isTelevision) {
              setSelectedFile(reader.result);
            }

            if (isRaiseReport) {
              setSelectedFilesMulti((prevFiles) => [
                ...prevFiles,
                reader.result,
              ]);
            }
          };
          setOpenCamera(false);
          setOpenDialog(false);
        } else {
          // If the file size is greater than 1MB, compress it
          const img = new Image();
          const reader = new FileReader();

          reader.onload = (event) => {
            img.src = event.target.result;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              const maxWidth = 1000; // Adjust this as needed
              const maxHeight = 1000; // Adjust this as needed
              let width = img.width;
              let height = img.height;

              // Calculate the new dimensions
              if (width > height && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              } else if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              // Start with a higher quality and decrease it gradually
              let quality = 1.0; // Further increased initial quality
              let compressedDataUrl = canvas.toDataURL(blob.type, quality);

              while (compressedDataUrl.length > 1048576 && quality > 0.3) {
                quality -= 0.02; // Decrease the quality in smaller steps
                compressedDataUrl = canvas.toDataURL(blob.type, quality);
              }

              // Ensure the final size is close to 1MB but not greater
              if (isResolve && !isTelevision) {
                setSelectedFile(compressedDataUrl);
              }

              if (isRaiseReport) {
                setSelectedFilesMulti((prevFiles) => [
                  ...prevFiles,
                  compressedDataUrl,
                ]);
              }
              setOpenDialog(false);
            };
          };

          reader.readAsDataURL(blob);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const handleRemoveImage = (index) => {
    setSelectedFilesMulti((prevFiles) =>
      prevFiles.filter((_, i) => i !== index)
    );
  };

  const resetForm = () => {
    goBack();
  };

  const { mutate, isLoading: isReportCreateLoading } = useMutation(
    createTaskSheetsIssueData
  );
  const { mutate: updateReportMutation, isLoading: isReportUpdateLoading } =
    useMutation(updateTaskSheetsIssueData);
  //handle report issue submit

  const checkForAllFields =
    departments.length > 0 &&
    reportingManager.length > 0 &&
    hallNo !== "" &&
    issue !== "";

  const reportingManagerSolutions = reportingManager?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      solution: null,
      solutionDate: null,
      seekedDate: null,
      seekReason: null,
      image: null,
      accepted: null,
      rejectedCount: 0,
    };
  });

  const handleSubmitReportIssue = () => {
    const payload = {
      date: today.format("YYYY-MM-DD"),
      department: departments,
      reportingManager: reportingManager,
      reportingManagerSolution: reportingManagerSolutions,
      description: issue,
      // ...(selectedFile && { image: selectedFile }),
      image: selectedFilesMulti?.length > 0 ? selectedFilesMulti : null,
      // video: urlFile ? urlFile : null,
      status: "PENDING",
      hall: hallNo,
    };

    mutate(payload, {
      onSuccess: (res) => {
        handleToastMsg("Data submitted successfully", "success");
        queryClient.invalidateQueries("task-sheets-report-issue");
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  const managerId = parseInt(localStorage.getItem("id"), 10);

  const managerIdFilter = showIssueData?.reportingManager?.filter(
    (item) => parseInt(item.id, 10) === managerId
  );

  const otherManagerResponse =
    showIssueData?.reportingManagerSolution?.filter(
      (item) => parseInt(item.id, 10) !== managerId
    ) || [];

  // const existManagerResponse =
  //   showIssueData?.reportingManagerSolution?.filter(
  //     (item) => parseInt(item.id, 10) === managerId
  //   ) || [];

  const existManagerResponse = useMemo(() => {
    return (
      showIssueData?.reportingManagerSolution?.filter(
        (item) => parseInt(item.id, 10) === managerId
      ) || []
    );
  }, [showIssueData?.reportingManagerSolution, managerId]);

  useEffect(() => {
    if (existManagerResponse[0]?.solution) {
      setSolution(existManagerResponse[0]?.solution);
    }

    if (existManagerResponse[0]?.image) {
      setSelectedFile(existManagerResponse[0]?.image);
    }
  }, [existManagerResponse]);

  //handle update the issue reports data (seek or resolve)
  const handleSeekOrResolve = () => {
    const params = {
      payload: {
        ...showIssueData,
        ...(isSeek && {
          reportingManagerSolution:
            otherManagerResponse?.length > 0
              ? [
                  ...otherManagerResponse,
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    solution:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solution
                        : null,
                    seekReason: seekReason,
                    solutionDate:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solutionDate
                        : null,
                    seekedDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                    // image: existManagerResponse[0]?.image || selectedFile,
                    image: selectedFile,
                    accepted: existManagerResponse[0]?.accepted,
                    rejectedCount: existManagerResponse[0]?.rejectedCount,
                  },
                ]
              : [
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    solution:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solution
                        : null,
                    seekReason: seekReason,
                    solutionDate:
                      existManagerResponse?.length > 0
                        ? existManagerResponse[0]?.solutionDate
                        : null,
                    seekedDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                    // image: existManagerResponse[0]?.image || selectedFile,
                    image: selectedFile,
                    accepted: existManagerResponse[0]?.accepted,
                    rejectedCount: existManagerResponse[0]?.rejectedCount,
                  },
                ],
        }),
        ...(isResolve && {
          reportingManagerSolution:
            otherManagerResponse.length > 0
              ? [
                  ...otherManagerResponse,
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    seekedDate:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekedDate
                        : null,
                    seekReason:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekReason
                        : null,
                    solutionDate: today.toISOString(),
                    solution: solution,
                    // image: existManagerResponse[0]?.image || selectedFile,
                    image: selectedFile,
                    accepted: null,
                    rejectedCount: existManagerResponse[0]?.rejectedCount,
                  },
                ]
              : [
                  {
                    id:
                      managerIdFilter?.length > 0 ? managerIdFilter[0]?.id : "",
                    name:
                      managerIdFilter?.length > 0
                        ? managerIdFilter[0]?.name
                        : "",
                    seekedDate:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekedDate
                        : null,
                    seekReason:
                      existManagerResponse.length > 0
                        ? existManagerResponse[0]?.seekReason
                        : null,
                    solutionDate: today.toISOString(),
                    solution: solution,
                    // image: existManagerResponse[0]?.image || selectedFile,
                    image: selectedFile,
                    accepted: null,
                    rejectedCount: existManagerResponse[0]?.rejectedCount,
                  },
                ],
        }),
      },
      id: showIssueData?.id,
    };

    updateReportMutation(params, {
      onSuccess: (res) => {
        handleToastMsg("Data updates successfully", "success");
        queryClient.invalidateQueries("task-sheets-report-issue");
        resetForm();
      },
      onError: (error) => {
        handleToastMsg("Data Error Occurred", "error");
      },
    });
  };

  const [openNew, setOpenNew] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpenNew(true);
  };

  const handleClose = () => {
    setOpenNew(false);
  };

  //this is for checking the seeked date or not

  const isManagerResponse = showIssueData?.reportingManagerSolution?.filter(
    (item) => parseInt(item.id, 10) === managerId
  );

  //this is for checking to show the date select (DatePicker)
  const showSeekDateSelect =
    isManagerResponse?.length > 0
      ? showIssueData?.reportingManagerSolution === null ||
        isManagerResponse[0]?.seekedDate === null
      : true;

  //this is for checking to show the seeked date if there is in the data thats why we have default false
  const showSeekedDate =
    isManagerResponse?.length > 0
      ? isManagerResponse[0]?.seekedDate !== null
      : false;

  return (
    <Grid container item xs={12} alignContent={"flex-start"} rowGap={3}>
      <Grid
        container
        item
        xs={12}
        sx={{
          mt: {
            xs: "15px",
            sm: "0px",
          },
        }}
      >
        <Button
          onClick={handleGoBack}
          style={{
            color: "black",
            textTransform: "none",
            outline: "1px solid grey",
          }}
        >
          <ArrowBackIcon /> <span style={{ paddingLeft: "10px" }}>Back</span>
        </Button>
      </Grid>

      {isManager && (
        <>
          <Grid container item xs={12} rowGap={3}>
            <Grid container item xs={12} alignItems={"center"}>
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}>Sl No :</span>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.slNo}
                </span>
              </Grid>
              <Grid
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
              >
                <span style={{ fontWeight: "600" }}>Department :</span>{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.department
                    ?.map((item) => item)
                    .join(", ")}{" "}
                </span>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems={"center"}>
              {" "}
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}> Date :</span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {dayjs(showIssueData?.date).format("DD-MM-YYYY")}
                </span>
              </Grid>
              <Grid
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
              >
                <span style={{ fontWeight: "600" }}>Reported By :</span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.reportedBy}
                </span>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems={"center"}>
              {" "}
              <Grid item xs={12} sm={6} md={6}>
                <span style={{ fontWeight: "600" }}> Hall No : </span>
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {showIssueData?.hall}{" "}
                </span>
              </Grid>
              {isSeek && !isTelevision && showSeekDateSelect && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  alignItems={"center"}
                >
                  <Grid
                    sx={{
                      "@media (max-width: 600px)": {
                        marginTop: "20px",
                      },
                    }}
                    item
                    xs={12}
                    sm={3.5}
                    md={2}
                  >
                    <span style={{ fontWeight: "600" }}> Seek Date : </span>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ marginTop: "10px" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        label="Date"
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(newValue)}
                        inputFormat="DD/MM/YYYY"
                        shouldDisableDate={(date) =>
                          date.isBefore(dayjs(), "day")
                        }
                        sx={{
                          "@media (max-width: 600px)": {
                            width: "100%",
                          },
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
              {showSeekedDate && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  alignItems={"center"}
                >
                  <Grid
                    sx={{
                      "@media (max-width: 600px)": {
                        marginTop: "20px",
                      },
                    }}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                  >
                    <span style={{ fontWeight: "600" }}>Seeked Date :</span>
                    <span style={{ marginLeft: "5px" }}>
                      {dayjs(isManagerResponse[0]?.seekedDate).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} rowGap={3}>
            <Grid item xs={12}>
              <span style={{ fontWeight: "600" }}>Description of issue</span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                value={showIssueData?.description}
                disabled={isManager}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid container item xs={12} sm={12} md={12} rowGap={3}>
              <Grid
                container
                item
                xs={12}
                rowGap={2}
                alignContent={"flex-start"}
              >
                <Grid item xs={12}>
                  <span style={{ fontWeight: "600" }}>Issue image</span>
                </Grid>
                {showIssueData?.image?.length > 0 ? (
                  showIssueData?.image?.map((imgUrl) => {
                    return (
                      <Grid item xs={12} sm={6} md={3}>
                        <img
                          src={imgUrl}
                          alt="Issued"
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClickOpen(imgUrl)}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <p>No image preview</p>
                )}
              </Grid>

              {/* <Grid container item xs={12} sm={12} md={12} rowGap={3}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  mb={2}
                  md={3}
                  rowGap={2}
                  alignContent={"flex-start"}
                >
                  <Grid item xs={12}>
                    <span style={{ fontWeight: "600" }}>Issue video</span>
                  </Grid>
                  {showIssueData?.video?.length > 0 ? (
                    <Grid item xs={12}>
                      <video
                        src={showIssueData?.video}
                        alt="Issued"
                        controls
                        style={{
                          height: "200px",
                          width: "200px",
                        }}
                      />
                    </Grid>
                  ) : (
                    <p>No video preview</p>
                  )}
                </Grid>
              </Grid> */}

              {isSeek && !isTelevision && showSeekDateSelect && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  rowGap={2}
                  mb={2}
                  alignContent={"flex-start"}
                >
                  <Grid
                    sx={{
                      "@media (max-width: 600px)": {
                        marginTop: "20px",
                      },
                    }}
                    item
                    xs={12}
                  >
                    <span style={{ fontWeight: "600" }}>Reason for Seek</span>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      rows={4}
                      value={seekReason}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setSeekReason(e.target.value)}
                    />
                  </Grid>
                </Grid>
              )}

              {isResolve && !isTelevision && (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    mb={2}
                    rowGap={2}
                    alignContent={"flex-start"}
                  >
                    <Grid item xs={12}>
                      <span style={{ fontWeight: "600" }}>Resolve image</span>
                    </Grid>
                    {selectedFile ? (
                      <>
                        {" "}
                        <Grid item>
                          <img
                            src={selectedFile}
                            alt="Uploaded"
                            style={{
                              height: "200px",
                              width: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </Grid>{" "}
                        <Grid item style={{ marginLeft: "10px" }}>
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectedFile(null)}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} sm={6} md={6}>
                        <Button
                          variant="contained"
                          style={{ textTransform: "none" }}
                          onClick={handleOpenDialog}
                          fullWidth
                        >
                          Upload
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item xs={12} sm={12} md={6}>
                    {isResolve && !isTelevision && (
                      <>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          rowGap={2}
                          mb={2}
                          alignContent={"flex-start"}
                        >
                          <Grid
                            sx={{
                              "@media (max-width: 600px)": {
                                marginTop: "20px",
                              },
                            }}
                            item
                            xs={12}
                          >
                            <span style={{ fontWeight: "600" }}>Solution</span>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              multiline
                              rows={4}
                              value={solution}
                              variant="outlined"
                              fullWidth
                              onChange={(e) => setSolution(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                // width: "200px",
                width: window.innerWidth <= 600 ? "100%" : "200px",
                textTransform: "none",
                background: `${isResolve && "#28A745"}`,
              }}
              disabled={
                isReportUpdateLoading ||
                (isSeek
                  ? selectedDate === null || seekReason === ""
                  : solution === "")
              }
              onClick={() => handleSeekOrResolve()}
            >
              {isReportUpdateLoading ? (
                <CircularProgress size={25} />
              ) : isManager && isSeek ? (
                "Seek"
              ) : (
                isManager && isResolve && "Resolve"
              )}
            </Button>
          </Grid>
        </>
      )}

      {/**this is for employee or sub manager raise report */}
      {isRaiseReport && (
        <>
          <Grid container item xs={12} rowGap={3}>
            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  container
                  style={{ fontWeight: "600" }}
                  item
                  xs={12}
                  sm={3}
                  md={3.2}
                >
                  Department :
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      marginLeft: "30px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginLeft: "0",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-department">
                      Select Department
                    </InputLabel>
                    <Select
                      labelId="select-department"
                      label="Select Department"
                      multiple
                      value={departments}
                      onChange={(e) => setDepartments(e.target.value)}
                      variant="outlined"
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {DEPARTMENTS_TASKSHEETS?.map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          <Checkbox checked={departments?.indexOf(dept) > -1} />
                          <ListItemText primary={dept} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  "@media (max-width: 600px)": {
                    marginTop: "20px",
                  },
                }}
                item
                xs={12}
                sm={6}
                md={6}
                alignItems={"center"}
              >
                <span style={{ fontWeight: "600" }}> Date :</span>{" "}
                <p style={{ marginLeft: "20px" }}>
                  {today.format("DD-MM-YYYY")}
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {" "}
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  style={{ fontWeight: "600" }}
                  container
                  item
                  xs={12}
                  sm={3}
                  md={3.2}
                >
                  Reporting Manager :{" "}
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      marginLeft: "30px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginLeft: "0",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-manager">Select Manager</InputLabel>
                    <Select
                      labelId="select-manager"
                      label="Select Manager"
                      multiple
                      value={reportingManager?.map((manager) => manager.id)}
                      onChange={handleManagerChange}
                      variant="outlined"
                      renderValue={(selected) =>
                        selected
                          .map((id) => {
                            const manager = filteredManagers?.find(
                              (mgr) => mgr.id === id
                            );
                            return manager ? manager.name : "";
                          })
                          .join(", ")
                      }
                    >
                      {/* {filteredManagers?.length > 0 &&
                        filteredManagers?.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={manager.id}
                            name={manager.name}
                          >
                            <Checkbox
                              checked={
                                reportingManager?.indexOf(manager.id) > -1
                              }
                            />
                            <ListItemText primary={manager.name} />
                          </MenuItem>
                        ))} */}

                      {filteredManagers?.length > 0 &&
                        filteredManagers?.map((manager) => (
                          <MenuItem
                            key={manager.id}
                            value={manager.id}
                            onClick={() => toggleManagerSelection(manager)}
                          >
                            <Checkbox
                              checked={reportingManager?.some(
                                (mgr) => mgr.id === manager.id
                              )}
                            />
                            <ListItemText primary={manager.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={6} md={6} alignItems={"center"}>
                <Grid
                  sx={{
                    fontWeight: "600",
                    "@media (max-width: 600px)": {
                      marginTop: "20px",
                    },
                  }}
                  container
                  item
                  xs={12}
                  sm={1.5}
                  md={1.5}
                >
                  Hall No :{" "}
                </Grid>
                <Grid container item xs={12} sm={6} md={6}>
                  <FormControl
                    sx={{
                      width: "200px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        marginTop: "20px",
                      },
                    }}
                  >
                    <InputLabel id="select-hall">Select Hall no</InputLabel>
                    <Select
                      labelId="select-hall"
                      label="Select Hall no"
                      value={hallNo}
                      onChange={(e) => setHallNo(e.target.value)}
                      variant="outlined"
                    >
                      {HALL_NO?.map((hall) => (
                        <MenuItem key={hall} value={hall}>
                          {hall}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} rowGap={2}>
            <Grid item style={{ fontWeight: "600" }} xs={12}>
              Description of issue :
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={4}
                value={issue}
                variant="outlined"
                fullWidth
                onChange={(e) => setIssue(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} alignContent={"flex-start"}>
            <Grid mt={4} container item xs={12} sm={12} md={12} rowGap={2}>
              <Grid style={{ fontWeight: "600" }} item xs={12}>
                Upload Image
              </Grid>
              <Grid container item xs={12} md={12} rowGap={5}>
                {selectedFilesMulti?.length > 0 ? (
                  selectedFilesMulti?.map((file, index) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      spacing={5}
                      key={index}
                    >
                      <Grid item style={{ position: "relative" }}>
                        <img
                          src={file}
                          alt={`Uploaded ${index + 1}`}
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <Grid item>
                          <CloseIcon
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "8%",
                              right: "-5%",
                            }}
                            onClick={() => handleRemoveImage(index)}
                          />
                        </Grid>
                      </Grid>{" "}
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      variant="contained"
                      style={{ textTransform: "none" }}
                      onClick={handleOpenDialog}
                      fullWidth
                      sx={{ height: "40px" }}
                    >
                      Upload
                    </Button>
                  </Grid>
                )}
              </Grid>
              {selectedFilesMulti.length > 0 && (
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", marginTop: "10px" }}
                    onClick={handleOpenDialog}
                    fullWidth
                    sx={{ height: "40px" }}
                  >
                    Add More Images
                  </Button>
                </Grid>
              )}
            </Grid>

            {/* <Grid mt={4} container item xs={12} sm={6.1} md={6} rowGap={2}>
              <Grid style={{ fontWeight: "600" }} item xs={12}>
                Upload video
              </Grid>

              <Grid container item xs={12} md={6}>
                {selectedVideo ? (
                  <>
                    {" "}
                    <Grid item style={{ position: "relative" }}>
                      <video
                        src={selectedVideo}
                        alt="Uploaded"
                        controls
                        style={{
                          height: "200px",
                          width: "200px",
                        }}
                      />
                      <Grid item>
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "7%",
                            right: "-5%",
                          }}
                          onClick={() => {
                            setSelectedVideo(null);
                            setUrlFile(null);
                            setVideoRecordUrl(null);
                            setVideoRecordFile(null);
                          }}
                        />
                      </Grid>
                    </Grid>{" "}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={handleOpenDialogVideo}
                    fullWidth
                    sx={{ height: "40px" }}
                  >
                    Upload
                  </Button>
                )}
              </Grid>
            </Grid> */}
          </Grid>

          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: window.innerWidth <= 600 ? "100%" : "200px",
                textTransform: "none",
              }}
              disabled={isReportCreateLoading || !checkForAllFields}
              onClick={handleSubmitReportIssue}
            >
              {isReportCreateLoading ? (
                <CircularProgress size={25} />
              ) : (
                "Report issue"
              )}
            </Button>
          </Grid>
        </>
      )}

      {/**this dialog box for showing the image to the manager */}
      <Dialog open={openNew} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="custom-scrollbar">
          <Grid>
            <img
              src={selectedImage}
              alt="issue"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      {/**this dialog box for select the image when raise an report */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              {" "}
              Upload Image{" "}
            </Grid>
            <Grid container item xs={6} justifyContent={"flex-end"}>
              {" "}
              <Button
                onClick={handleCloseDialog}
                variant="outlined"
                color="primary"
                style={{
                  textTransform: "none",
                  background: "#e03131",
                  color: "#FFF",
                }}
                // startIcon={<CloseIcon />}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!openCamera ? (
            <>
              <Button
                variant="contained"
                component="label"
                style={{ textTransform: "none" }}
                fullWidth
              >
                Select Image File
                {isResolve && !isTelevision && (
                  <input
                    type="file"
                    hidden
                    accept="image/jpeg, image/png"
                    onChange={handleFileChange}
                  />
                )}
                {isRaiseReport && (
                  <input
                    type="file"
                    multiple
                    hidden
                    accept="image/jpeg, image/png"
                    onChange={handleFileChange}
                  />
                )}
              </Button>
              <Button
                variant="contained"
                fullWidth
                style={{ marginTop: "20px", textTransform: "none" }}
                onClick={handleCameraClick}
              >
                Open Camera
              </Button>
            </>
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{
                facingMode: facingMode,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {openCamera && (
            <>
              <Button
                onClick={capture}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                startIcon={<LinkedCameraIcon />}
              >
                Capture
              </Button>

              <Button
                onClick={handleToggleCamera}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                startIcon={<CameraswitchIcon />}
              >
                Switch Camera
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogVideo} onClose={handleCloseDialogVideo}>
        <DialogTitle>
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              Upload Video
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Button
                onClick={handleCloseDialogVideo}
                variant="outlined"
                color="primary"
                style={{
                  textTransform: "none",
                  background: "#e03131",
                  color: "#FFF",
                }}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!openVideoCamera ? (
            <>
              <Button
                variant="contained"
                component="label"
                style={{ textTransform: "none" }}
                fullWidth
              >
                Select Video File
                <input
                  type="file"
                  hidden
                  accept=".mp4"
                  onChange={handleVideoFileChange}
                />
              </Button>
              <Button
                variant="contained"
                fullWidth
                style={{ marginTop: "20px", textTransform: "none" }}
                onClick={handleCameraVideoClick}
              >
                Open Camera
              </Button>
            </>
          ) : (
            <>
              <Webcam
                audio={false}
                ref={webcamRefVideo}
                screenshotFormat="image/jpeg"
                width="100%"
                videoConstraints={{
                  facingMode: facingMode,
                }}
              />

              <Grid sx={{ position: "absolute", bottom: 80, left: 30 }}>
                <video ref={videoRef} autoPlay playsInline muted />
                {isRecording && (
                  <div
                    style={{
                      width: "40px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {formatTime(recordingTime)}
                  </div>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {openVideoCamera && (
            <Button
              onClick={handleStartStopRecording}
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
            >
              {isRecording ? <StopCircle /> : <PlayCircleOutline />}
              {isRecording ? "Stop Record" : "Start Record"}
            </Button>
          )}

          {openVideoCamera && !isRecording && (
            <Button
              onClick={captureVideo}
              variant="contained"
              color="primary"
              disabled={!videoRecordUrl}
              style={{ textTransform: "none" }}
              startIcon={<FileUpload />}
            >
              Upload Video
            </Button>
          )}
          {openVideoCamera && (
            <Button
              onClick={handleToggleCamera}
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
              startIcon={<CameraswitchIcon />}
            >
              Switch Camera
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={loadingVideo}>
        <DialogContent>
          <Grid sx={{ color: "red" }}>Wait for a minute</Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default TaskSheetReportAndResolve;
